
    import Vue, { PropOptions } from 'vue';
    import MultiRangeSlider from 'multi-range-slider-vue';

    export default Vue.extend({
        name: 'DualRangeSlider',
        components: {
            MultiRangeSlider,
        },
        props: {
            minValue: {
                type: Number,
                required: true,
            },
            maxValue: {
                type: Number,
                required: true,
            },
            min: {
                type: Number,
                default: 0,
            },
            max: {
                type: Number,
                default: 100,
            },
            step: {
                type: Number,
                default: 1,
            },
            rangeMargin: {
                type: Number,
                default: 1,
            },
            ruler: {
                type: Boolean,
                default: false,
            },
            label: {
                type: Boolean,
                default: true,
            },
            showTooltip: {
                type: Boolean,
                default: true,
            },
            formatRangeValue: {
                type: Function,
                default: null,
            } as PropOptions<Function | null>,
            formatThumbValue: {
                type: Function,
                default: null,
            } as PropOptions<Function | null>,
        },
        data() {
            return {
                showTooltipInternal: this.showTooltip || (true as boolean),
                tooltipStyle: {
                    left: '0px',
                    top: '0px',
                },
                sliderStyles: {
                    '--thumb-color': '#FF5733', // Dynamically set thumb color
                },
            };
        },

        watch: {
            showTooltip: {
                immediate: true,
                handler(newVal: boolean, oldVal: boolean) {
                    this.showTooltipInternal = newVal;
                },
            },
        },

        methods: {
            setValues(e: any) {
                this.$emit('input', e);
            },
            formattedValue(value: number): string {
                return this.formatThumbValue ? this.formatThumbValue(value) : value.toString();
            },
            formattedRangeValue(): number {
                return this.formatRangeValue ? this.formatRangeValue([this.minValue, this.maxValue]) : [this.minValue, this.maxValue];
            },
        },
    });
