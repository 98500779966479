/*
 * Copyright 2023-2025 Lola - All rights reserved.
 * File: publicRoutes.ts
 * Project: Lola
 */
import Error from '@/views/Error.vue';
import MatchCalculator from '@/views/MatchCalculator.vue';

export default [
    {
        path: '/pub/matchcalculator',
        name: 'match_calculator',
        component: MatchCalculator,
        meta: { requiresAuth: false },
    },
];
