
    import Vue from 'vue';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import PastDateRow from '@/components/dating/PastDateRow.vue';
    import PostDateSurveyCompleted from '@/components/dating/CompletedPostDateSurvey.vue';
    import { DatingService, SharedConstants, CompletedPostDateSurvey } from '@bostonventurestudio/lola-api';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { use } from 'vue/types/umd';

    export default Vue.extend({
        name: 'CompletedPostDateSurveys',

        components: {
            'past-date': PastDateRow,
            'completed-post-date-survey': PostDateSurveyCompleted,
        },

        props: {},

        data() {
            return {
                completedSurveys: [] as CompletedPostDateSurvey[],
                fetchComplete: false,
                filters: {} as any,
                pagination: {
                    page: 1,
                    pageSize: SharedConstants.COMPLETED_POST_DATE_SURVEY_PAGE_SIZE,
                    totalRecords: 0,
                },
                currentPage: 1,
                expandedRow: -1,
            };
        },

        watch: {},

        mounted() {
            this.fetchCompletedSurveys({ page: 1, pageSize: SharedConstants.COMPLETED_POST_DATE_SURVEY_PAGE_SIZE });
        },

        computed: {
            hasAccess(): boolean {
                return (
                    this.$store.getters.isSignedIn &&
                    (!this.gettingRecordForAnotherUser || (this.gettingRecordForAnotherUser && RoleUtils.CanSupportUsers()))
                );
            },
            gettingRecordForAnotherUser(): boolean {
                return this.$route.params.userId != this.$store.getters.getAuthedUserId;
            },
            userId(): number {
                return parseInt(this.$route.params.userId == this.$store.getters.getAuthedUserId ? '' : this.$route.params.userId);
            },
        },

        methods: {
            async fetchCompletedSurveys(filters: any) {
                try {
                    let query = {};
                    if (this.userId > 0)
                        query = {
                            userId: this.userId,
                            page: filters.page || 1, // Use the page from filters, default to 1 if not provided
                            pageSize: filters.pageSize || SharedConstants.COMPLETED_POST_DATE_SURVEY_PAGE_SIZE, // Use pageSize from filters or default constant
                        };
                    const ret = await ApiUtils.apiWrapper(DatingService.getCompletedPostDateSurveys, query);
                    if (ret && ret.postDateSurveys && ret.postDateSurveys.length > 0) {
                        this.completedSurveys = ret.postDateSurveys;
                        console.log('total records:', ret.totalRecords);
                        this.pagination = {
                            page: ret.page ?? 1,
                            pageSize: ret.pageSize ?? SharedConstants.COMPLETED_POST_DATE_SURVEY_PAGE_SIZE,
                            totalRecords: ret.totalRecords ?? 0,
                        };
                    }
                    console.log('Completed Surveys:', this.completedSurveys);
                    console.log('userId:', this.userId);
                    this.fetchComplete = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            rowSelected(idx: number) {
                if (idx < 0) {
                    logInvalidParams(this.$options.name, 'rowSelected');
                    return;
                }

                const item = this.completedSurveys[idx];
                if (!item || !item.matchId || !item.partnerUserId || item.partnerUserId <= 0) {
                    logInvalidParams(this.$options.name, 'rowSelcted - invalid partnerUserId');
                    return;
                }

                if (this.expandedRow === idx) {
                    this.expandedRow = -1;
                } else {
                    this.expandedRow = idx;
                }
            },

            async handlePageChange(page: number) {
                // Update the page number and perform the search again
                var filters = this.filters;
                filters.page = page;
                await this.fetchCompletedSurveys(filters);
            },

            showPagination(): number {
                return this.completedSurveys && this.completedSurveys?.length;
            },
            /**
             * Returns a string representing the pagination information.
             * @returns {string} The pagination information.
             */
            getPaginationInfo(): string {
                var showing = (this.pagination.page - 1) * this.pagination.pageSize + 1;
                return (
                    'Showing ' +
                    showing +
                    ' - ' +
                    Math.min(this.pagination.page * this.pagination.pageSize, this.pagination.totalRecords) +
                    ' of ' +
                    this.pagination.totalRecords +
                    ' Surveys.'
                );
            },
        },
    });
