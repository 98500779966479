
    import EventBus from '@/eventbus';
    import Vue from 'vue';

    export default Vue.extend({
        name: 'MatchCalculatorApp',
        methods: {
            hasDevSiteDownAccess(): boolean {
                return document.cookie.match(new RegExp('LetMeIn' + '=([^;]+)')) !== null;
            },
            handleScroll() {
                if (this.isMobile) {
                    this.isScrolled = window.scrollY > 75;
                }
            },
            checkMobile() {
                this.isMobile = window.innerWidth < 768;
            },
        },
        created() {
            // Add scroll and resize event listeners
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', this.checkMobile);
            this.checkMobile(); // Initial check
        },
        beforeDestroy() {
            // Remove scroll and resize event listeners
            window.removeEventListener('scroll', this.handleScroll);
            window.removeEventListener('resize', this.checkMobile);
        },
        computed: {},
        data() {
            return {
                isScrolled: false,
                isMobile: false,
            };
        },
    });
