
    import Vue from 'vue';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import Avatar from '@/components/Avatar.vue';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import { UserService, PublicUser } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'BlockedUsers',

        components: {
            'url-avatar': Avatar,
            'user-name-display': UserNameDisplay,
        },

        data() {
            return {
                users: [] as PublicUser[],
                usersBlockedMe: [] as PublicUser[],
                blockedByError: false,
                blockedPage: 1,
                blockedPageSize: 20,
                totalBlockedCount: 0,

                blockedByPage: 1,
                totalBlockedByCount: 0,
                currentUserId: undefined as number | undefined,
            };
        },

        mounted() {
            this.fetchBlockedUsers(this.currentUserId);
            this.fetchUsersBlockedMe(this.currentUserId);
        },

        methods: {
            async fetchBlockedUsers(id: number | undefined) {
                try {
                    const ret = await ApiUtils.apiWrapper(UserService.getBlockedUsers, {
                        userId: this.getUserId(),
                        page: this.blockedPage,
                        pageSize: this.blockedPageSize,
                    });
                    this.users = ret?.list || [];
                    this.totalBlockedCount = ret?.totalCount || 0;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchUsersBlockedMe(id: number | undefined) {
                try {
                    const ret = await ApiUtils.apiWrapper(UserService.getBlockedByUsers, {
                        userId: this.getUserId(),
                        page: this.blockedByPage,
                        pageSize: this.blockedPageSize,
                    });
                    this.usersBlockedMe = ret?.list || [];
                    this.totalBlockedByCount = ret?.totalCount || 0;
                    this.blockedByError = false;
                } catch (error: any) {
                    if (error.response?.data?.message?.includes('PermissionDenied')) {
                        this.blockedByError = true;
                    } else {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },

            async unblockUser(userId: number | undefined) {
                if (!userId) {
                    logInvalidParams(this.$options.name, 'unblockUser');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(UserService.unblockUser, userId);
                    this.fetchBlockedUsers(this.currentUserId);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            getUserId(): string {
                const requestUserId = this.$route.params.userId;
                const authedUserId = this.$store.getters.getAuthedUserId;
                return requestUserId && requestUserId !== authedUserId ? requestUserId : '0';
            },

            nextPageBlockedUsers() {
                const totalPages = Math.ceil(this.totalBlockedCount / this.blockedPageSize);
                if (this.blockedPage < totalPages) {
                    this.blockedPage++;
                    this.fetchBlockedUsers(this.currentUserId);
                }
            },

            prevPageBlockedUsers() {
                if (this.blockedPage > 1) {
                    this.blockedPage--;
                    this.fetchBlockedUsers(this.currentUserId);
                }
            },

            nextPageBlockedByUsers() {
                const totalPages = Math.ceil(this.totalBlockedByCount / this.blockedPageSize);
                if (this.blockedByPage < totalPages) {
                    this.blockedByPage++;
                    this.fetchUsersBlockedMe(this.currentUserId);
                }
            },

            prevPageBlockedByUsers() {
                if (this.blockedByPage > 1) {
                    this.blockedByPage--;
                    this.fetchUsersBlockedMe(this.currentUserId);
                }
            },
        },
    });
