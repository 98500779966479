/*
 * Copyright 2022-2023 Lola - All rights reserved.
 * File: mainroutes.ts
 * Project: lola
 *
 * cspell:ignore aupr
 */
import * as constants from '@/tsfiles/constants';
import MarketingHome from '@/views/MarketingHome.vue';
import SigninHome from '@/views/SigninHome.vue';
import EmailVerification from '@/views/EmailVerification.vue';
import Unsubscribe from '@/views/user/Unsubscribe.vue';
import SigninComplete from '@/views/SigninComplete.vue';
import AuthedUserPageRedirect from '@/views/user/AuthedUserPageRedirect.vue';
import Error from '@/views/Error.vue';

import ServerAdmin from '@/views/admin/ServerAdmin.vue';
import SupportAdmin from '@/views/admin/SupportAdmin.vue';
import Ticket from '@/views/admin/Ticket.vue';
import StatsAdmin from '@/views/admin/StatsAdmin.vue';

import Share from '@/views/Share.vue';

import Search from '@/views/Search.vue';
import NotificationsPage from '@/views/user/NotificationsPage.vue';
import PublicProfile from '@/views/user/PublicProfile.vue';
import PrivateProfile from '@/views/user/PrivateProfile.vue';
import UserCalendar from '@/views/user/UserCalendar.vue';
import UserProspects from '@/views/user/UserProspects.vue';
import UserProposals from '@/views/user/UserProposals.vue';
import UserDates from '@/views/user/UserDates.vue';
import UserDate from '@/views/user/UserDate.vue';
import UserPastDate from '@/views/user/UserPastDate.vue';
import UserPastDates from '@/views/user/UserPastDates.vue';
import Settings from '@/views/user/Settings.vue';
import PostDateSurveys from '@/views/user/PostDateSurveys.vue';
import CompletedPostDateSurveys from '@/views/user/CompletedPostDateSurveys.vue';
import BlockedUsers from '@/views/user/BlockedUsers.vue';
import QrcodeApp from '@/views/QrcodeApp.vue';
import SquarespaceRedirect from '@/views/user/SquarespaceRedirect.vue';
import DatingPool from '@/views/admin/DatingPool.vue';
import UserScorecards from '@/views/user/UserScorecards.vue';

export default [
    {
        path: '/',
        name: constants.ROUTE_MARKETING,
        component: MarketingHome,
    },

    {
        path: '/firebasesignin',
        name: constants.ROUTE_FIREBASE_SIGNIN,
        props: true,
        component: SigninHome,
    },

    {
        path: '/signincomplete',
        name: 'signin_complete',
        component: SigninComplete,
        meta: { requiresAuth: true },
    },

    {
        path: '/emailverification',
        name: 'email_verification',
        component: EmailVerification,
        meta: { requiresAuth: true },
    },

    {
        path: '/share/:token',
        name: 'share',
        component: Share,
    },

    {
        path: '/error',
        name: 'error',
        component: Error,
        props: true,
    },

    {
        path: '/unsubscribe',
        name: 'unsubscribe',
        component: Unsubscribe,
    },

    //
    // Redirect to authed user page.  This is used when signed out, and
    // the anonymous user clicks on a menu choice that requires signin.  When
    // signin is complete, the user should go to the tab they were clicking on.
    // 'aupr' stands for authed-user-page.
    //
    {
        path: '/aupr',
        name: 'authed_user_page_redirect',
        props: true,
        component: AuthedUserPageRedirect,
    },

    {
        path: '/search',
        name: constants.ROUTE_SEARCH,
        props: true,
        component: Search,
    },

    //
    // spr stands for 'SquareSpaceRedirect'.  We use squarespace to
    // simplify URLs for ambassadors.  For example, lola.com/marlee should
    // redirect to m.lola.com/spr?uid=99.
    //
    {
        path: '/spr',
        name: 'squarespace_redirect',
        props: true,
        component: SquarespaceRedirect,
    },

    //
    // aqr stands for 'App Qrcode'.  It's meant as the desktop
    // webpage showing a qrcode that the user can process with their phone.
    // The qrcode url will be a branch link.
    //
    {
        path: '/aqr',
        name: 'qrcode_app',
        props: true,
        component: QrcodeApp,
    },

    {
        path: '/:userId([0-9]+)',
        name: constants.ROUTE_USER_HOME,
        props: true,
        component: PublicProfile,
    },

    {
        path: '/:userId([0-9]+)/calendar',
        name: constants.ROUTE_USER_CALENDAR,
        props: true,
        component: UserCalendar,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/prospects',
        name: constants.ROUTE_USER_PROSPECTS,
        props: true,
        component: UserProspects,
        meta: { requiresAuth: true },
    },
    {
        path: '/:userId([0-9]+)/pending_proposals',
        name: constants.ROUTE_USER_PENDING_PROPOSALS,
        props: true,
        component: UserProposals,
        meta: { requiresAuth: true },
    },
    {
        path: '/:userId([0-9]+)/dates',
        name: constants.ROUTE_USER_DATES,
        props: true,
        component: UserDates,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/date',
        name: constants.ROUTE_USER_DATE,
        props: true,
        component: UserDate,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/pastdate',
        name: constants.ROUTE_USER_PAST_DATE,
        props: true,
        component: UserPastDate,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/pastdates',
        name: constants.ROUTE_USER_PAST_DATES,
        props: true,
        component: UserPastDates,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/profile',
        name: constants.ROUTE_USER_PROFILE,
        props: true,
        component: PrivateProfile,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/profile/settings',
        name: constants.ROUTE_USER_SETTINGS,
        props: true,
        component: Settings,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/postdatesurveys',
        name: constants.ROUTE_USER_POST_DATE_SURVEYS,
        props: true,
        component: PostDateSurveys,
        meta: { requiresAuth: true },
    },
    {
        path: '/:userId([0-9]+)/completedpostdatesurveys',
        name: constants.ROUTE_USER_COMPLETED_POST_DATE_SURVEYS,
        props: true,
        component: CompletedPostDateSurveys,
        meta: { requiresAuth: true },
    },
    {
        path: '/:userId([0-9]+)/dating-scorecards',
        name: constants.ROUTE_USER_DATING_SCORECARDS,
        props: true,
        component: UserScorecards,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/notifications',
        name: constants.ROUTE_USER_NOTIFICATIONS,
        props: true,
        component: NotificationsPage,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/blocked',
        name: constants.ROUTE_USER_BLOCKED,
        props: true,
        component: BlockedUsers,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/admin/server',
        name: constants.ROUTE_ADMIN_SERVER,
        props: true,
        component: ServerAdmin,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/admin/support',
        name: constants.ROUTE_ADMIN_SUPPORT,
        props: true,
        component: SupportAdmin,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/admin/ticket',
        name: constants.ROUTE_ADMIN_TICKET,
        props: true,
        component: Ticket,
        meta: { requiresAuth: true },
    },

    {
        path: '/:userId([0-9]+)/admin/stats',
        name: constants.ROUTE_ADMIN_STATS,
        props: true,
        component: StatsAdmin,
        meta: { requiresAuth: true },
    },
    {
        path: '/admin/dating-pool',
        name: constants.ROUTE_DATING_POOL,
        props: true,
        component: DatingPool,
        meta: { requiresAuth: true },
    },
];
