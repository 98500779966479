
    import Vue from 'vue';
    import * as constants from '@/tsfiles/constants';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import UserProfile from '@/components/user/UserProfile.vue';
    import SupportProfileHeader from '@/components/admin/SupportProfileHeader.vue';
    import UserHistory from '@/components/admin/UserHistory.vue';
    import UserTickets from '@/components/admin/UserTickets.vue';
    import LivenessSnapshots from '@/components/admin/LivenessSnapshots.vue';
    import { AdminService, PublicUserForAdmin } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'PublicProfile',

        components: {
            'user-profile': UserProfile,
            'support-profile-header': SupportProfileHeader,
            'user-history': UserHistory,
            'user-tickets': UserTickets,
            'liveness-snapshots': LivenessSnapshots,
        },

        props: {},

        data() {
            return {
                saveDS: false,

                userForAdmin: undefined as PublicUserForAdmin | undefined,
                historyEnabled: false,
                showLiveness: false,
                eventBus: new Vue(),
            };
        },

        watch: {
            '$route.params.userId': {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal && newVal !== oldVal) {
                        this.checkRoute(this.$router.currentRoute.name as string, newVal);
                        if (this.isSupportAdmin) {
                            this.PublicUserForAdmin(newVal);
                        }
                    }
                },
            },
        },

        //
        // If we are getting to the profile page from outside, such as a different site,
        // We will save the DS part of adChannel with this user's Id.  We only have the
        // userId, so we need to pass along a variable telling the child component
        // to set the adChannel value.
        //
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                // access to component public instance via `vm`

                if (!from.name || from.name === '') {
                    // @ts-ignore
                    vm.saveDS = true;
                }
            });
        },

        computed: {
            isSupportAdmin() {
                return RoleUtils.CanSupportUsers();
            },
        },

        methods: {
            checkRoute(routeName: string, userId: string) {
                //
                // Only change the menu if it is the authed user's own page; otherwise,
                // no menu item is selected.
                //
                const isAuthedUser = this.$store.getters.isSignedIn && this.$store.getters.isAuthedUserId(userId.toString());
                let replaceRoute = false;

                //
                // If ROUTE_USER_HOME, meaning there is nothing after @{{userId}}, send to the
                // current menu route for real users and the admin page for admins.  For users, if
                // the current menu is empty, default to ROUTE_USER_CALENDAR.
                //
                if (isAuthedUser) {
                    if (routeName === constants.ROUTE_USER_HOME) {
                        if (this.$store.getters.getCurrentMenu === '') {
                            this.$store.commit('setCurrentMenu', constants.ROUTE_USER_CALENDAR);
                        }

                        replaceRoute = true;
                    } else {
                        this.$store.commit('setCurrentMenu', routeName);
                    }

                    // Push to desired route based on menu, unless already there.
                    if (replaceRoute) {
                        this.$router.replace({
                            name: this.$store.getters.getCurrentMenu,
                            params: { userId },
                        });
                    }
                } else {
                    //
                    // If not the authed user, set the DS adChannel info if not already set.
                    //
                    const adChannel = this.$store.getters.getAdChannel;
                    if (!adChannel.downloadSource || adChannel.downloadSource === '') {
                        this.$store.commit('setAdChannel', {
                            downloadSource: userId,
                        });
                    }
                }
            },

            // Fetch the PublicUserForAdmin user
            async PublicUserForAdmin(userId: number) {
                try {
                    this.userForAdmin = await ApiUtils.apiWrapper(AdminService.getPublicUser, userId);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            reloadUser() {
                if (!this.userForAdmin || !this.userForAdmin.publicUser || !this.userForAdmin.publicUser.userId) {
                    logInvalidParams(this.$options.name, 'reloadUser');
                    return;
                }

                this.eventBus.$emit('reload-user');
                this.PublicUserForAdmin(this.userForAdmin.publicUser.userId);
            },
            showUserProspects() {
                const userId = this.$route.params.userId;

                if (userId) {
                    const route = this.$router.resolve({
                        name: constants.ROUTE_USER_PROSPECTS,
                        params: { userId },
                    });
                    window.open(route.href, '_blank');
                }
            },
            showUserScorecards() {
                const userId = this.$route.params.userId;

                if (userId) {
                    const route = this.$router.resolve({
                        name: constants.ROUTE_USER_DATING_SCORECARDS,
                        params: { userId },
                    });
                    window.open(route.href, '_blank');
                }
            },
            showUserPendingPostDateSurveys() {
                const userId = this.$route.params.userId;

                if (userId) {
                    const route = this.$router.resolve({
                        name: constants.ROUTE_USER_POST_DATE_SURVEYS,
                        params: { userId },
                    });
                    window.open(route.href, '_blank');
                }
            },
            showUserCompletedPostDateSurveys() {
                const userId = this.$route.params.userId;

                if (userId) {
                    const route = this.$router.resolve({
                        name: constants.ROUTE_USER_COMPLETED_POST_DATE_SURVEYS,
                        params: { userId },
                    });
                    window.open(route.href, '_blank');
                }
            },
            showBlockedUsers() {
                const userId = this.$route.params.userId;

                if (userId) {
                    const route = this.$router.resolve({
                        name: constants.ROUTE_USER_BLOCKED,
                        params: { userId },
                    });
                    window.open(route.href, '_blank');
                }
            },
            toggleUserHistory(scrollToSection: boolean = false) {
                this.historyEnabled = !this.historyEnabled;
                // scroll to user history
                if (scrollToSection && this.historyEnabled) {
                    (this.$refs.userHistoryRef as HTMLInputElement).scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            },
        },
    });
