
    import Vue from 'vue';
    import html2canvas from 'html2canvas';
    import MultiCheckboxDropdown from '@/components/uiutils/MultiCheckboxDropdown.vue';
    import SingleSlider from '@/components/uiutils/SingleSlider.vue';
    import DualRangeSlider from '@/components/uiutils/DualRangeSlider.vue';

    const BOSTON_POPULATION = 653833;
    const ethnicityStats = new Map<string, number>([
        ['white', 0.478],
        ['black', 0.215],
        ['asian', 0.1],
        ['hispanic', 0.189],
        ['native', 0.003],
        ['pacific', 0.001],
    ]);
    const educationStats = new Map<string, number>([
        ['high_school', 0.889],
        ['some_college', 0.64],
        ['bachelors', 0.541],
        ['masters', 0.24],
        ['doctorate', 0.05],
    ]);
    const eyeStats = new Map<string, number>([
        ['brown', 0.45],
        ['blue', 0.27],
        ['hazel', 0.18],
        ['green', 0.09],
    ]);
    const hairStats = new Map<string, number>([
        ['black', 0.85],
        ['brown', 0.11],
        ['blonde', 0.02],
        ['red', 0.01],
    ]);
    const religionStats = new Map<string, number>([
        ['non_religious', 0.35],
        ['christian', 0.595],
        ['jewish', 0.01],
        ['islamic', 0.015],
        ['hindu', 0.01],
        ['other', 0.025],
    ]);

    interface Preferences {
        [key: string]: any;
    }

    export default Vue.extend({
        name: 'MatchCalculator',
        components: {
            MultiCheckboxDropdown,
            SingleSlider,
            DualRangeSlider,
        },
        data() {
            return {
                preferences: {
                    gender: 'Men',
                    age: { min: 25, max: 45 } as { min: number; max: number },
                    ageRange: [25, 45],
                    heightRange: [68, 77],
                    minIncome: 70000,
                    ethnicity: [] as string[],
                    education: [] as string[],
                    eyeColor: [] as string[],
                    hairColor: [] as string[],
                    religion: [] as string[],
                    smokes: 'No',
                    drinks: 'Either',
                    drugs: 'Either',
                    excludeMarried: 'Yes',
                    excludeObese: 'Yes',
                } as Preferences,
                result: { percentage: '', people: '' },
                matchingPeople: 0,
                genderOptions: ['Men', 'Women'],
                ageOptions: { min: 18, max: 80, step: 1, rangeMargin: 1 },
                heightOptions: { min: 60, max: 84, step: 1, rangeMargin: 3 },
                incomeOptions: { min: 0, max: 300000, step: 5000 },
                ethnicityOptions: [
                    { value: 'any', text: 'Any' },
                    { value: 'white', text: 'White' },
                    { value: 'black', text: 'Black' },
                    { value: 'asian', text: 'Asian' },
                    { value: 'hispanic', text: 'Hispanic or Latino' },
                    { value: 'native', text: 'American Indian' },
                    { value: 'pacific', text: 'Pacific Islander' },
                ],
                educationOptions: [
                    { value: 'any', text: 'Any' },
                    { value: 'high_school', text: 'High School Graduate' },
                    { value: 'some_college', text: 'Some College' },
                    { value: 'bachelors', text: "Bachelor's Degree" },
                    { value: 'masters', text: "Master's Degree" },
                    { value: 'doctorate', text: 'Doctorate' },
                ],
                eyeColorOptions: [
                    { value: 'any', text: 'Any' },
                    { value: 'brown', text: 'Brown' },
                    { value: 'blue', text: 'Blue' },
                    { value: 'hazel', text: 'Hazel' },
                    { value: 'green', text: 'Green' },
                ],
                hairColorOptions: [
                    { value: 'any', text: 'Any' },
                    { value: 'black', text: 'Black Hair' },
                    { value: 'brown', text: 'Brown Hair' },
                    { value: 'blonde', text: 'Blonde Hair' },
                    { value: 'red', text: 'Red Hair' },
                ],
                religionOptions: [
                    { value: 'any', text: 'Any' },
                    { value: 'non_religious', text: 'Non-religious' },
                    { value: 'christian', text: 'Christian' },
                    { value: 'jewish', text: 'Jewish' },
                    { value: 'islamic', text: 'Islamic' },
                    { value: 'hindu', text: 'Hinduism' },
                    { value: 'other', text: 'Other' },
                ],
                yesNoEitherOptions: ['Either', 'Yes', 'No'],
                yesNoOptions: ['Yes', 'No'],
            };
        },

        computed: {},

        watch: {
            preferences: {
                handler(newVal, oldValues) {},
                deep: true,
            },
        },

        methods: {
            formatIncome(value: number) {
                return `$${value.toLocaleString()}`;
            },
            formatRangeArray([low, high]: [number, number]) {
                return `${low} - ${high}`;
            },
            formatRangeThumb(val: number): string {
                return String(val);
            },
            formatHeightInches(num: number) {
                const ft = Math.floor(num / 12);
                const r = num % 12;
                return `${ft}'${r}"`;
            },
            formatHeightRange([low, high]: [number, number]) {
                return `${this.formatHeightInches(low)} - ${this.formatHeightInches(high)}`;
            },
            setPreferenceValue(key: string, value: any) {
                this.preferences[key] = value;
            },
            setGender(gender: string) {
                this.preferences.gender = gender;
            },
            setAge(e: any) {
                this.setPreferenceValue('ageRange', [e.minValue, e.maxValue]);
            },
            setHeight(e: any) {
                this.setPreferenceValue('heightRange', [e.minValue, e.maxValue]);
            },
            setEthnicity(newValues: string[]) {
                this.setPreferenceValue('ethnicity', newValues);
            },
            setEducation(newValues: string[]) {
                this.setPreferenceValue('education', newValues);
            },
            setEyeColor(newValues: string[]) {
                this.setPreferenceValue('eyeColor', newValues);
            },
            setHairColor(newValues: string[]) {
                this.setPreferenceValue('hairColor', newValues);
            },
            setReligion(newValues: string[]) {
                this.setPreferenceValue('religion', newValues);
            },
            setIncome(e: any) {
                this.setPreferenceValue('minIncome', e);
            },
            calculateMatch() {
                this.preferences.percentage = undefined;
                let probability = 100;
                probability *= this.preferences.gender === 'Women' ? 0.519 : 0.481;

                const ageWidth = Math.abs(this.preferences.ageRange[1] - this.preferences.ageRange[0]);
                probability *= Math.min(ageWidth / 62, 1);

                const hRange = Math.abs(this.preferences.heightRange[1] - this.preferences.heightRange[0]);
                probability *= Math.min(hRange / (4 * 2.7), 1);

                if (this.preferences.ethnicity.length > 0 && !this.preferences.ethnicity.includes('any')) {
                    let sumE = 0;
                    this.preferences.ethnicity.forEach((e: string) => {
                        sumE += ethnicityStats.get(e) || 0.01;
                    });
                    probability *= Math.min(sumE, 1);
                }

                if (this.preferences.minIncome >= 150000) probability *= 0.15;
                else if (this.preferences.minIncome >= 100000) probability *= 0.35;
                else if (this.preferences.minIncome >= 70000) probability *= 0.55;

                if (this.preferences.education.length > 0 && !this.preferences.education.includes('any')) {
                    let sumEdu = 0;
                    this.preferences.education.forEach((e: string) => {
                        sumEdu += educationStats.get(e) || 0.01;
                    });
                    probability *= Math.min(sumEdu, 1);
                }

                if (this.preferences.eyeColor.length > 0 && !this.preferences.eyeColor.includes('any')) {
                    let sumEye = 0;
                    this.preferences.eyeColor.forEach((e: string) => {
                        sumEye += eyeStats.get(e) || 0.01;
                    });
                    probability *= Math.min(sumEye, 1);
                }

                if (this.preferences.hairColor.length > 0 && !this.preferences.hairColor.includes('any')) {
                    let sumHair = 0;
                    this.preferences.hairColor.forEach((e: string) => {
                        sumHair += hairStats.get(e) || 0.01;
                    });
                    probability *= Math.min(sumHair, 1);
                }

                if (this.preferences.religion.length > 0 && !this.preferences.religion.includes('any')) {
                    let sumR = 0;
                    this.preferences.religion.forEach((e: string) => {
                        sumR += religionStats.get(e) || 0.01;
                    });
                    probability *= Math.min(sumR, 1);
                }

                if (this.preferences.smokes === 'No') {
                    probability *= this.preferences.gender === 'Men' ? 0.88 : 0.9;
                } else if (this.preferences.smokes === 'Yes') {
                    probability *= this.preferences.gender === 'Men' ? 0.12 : 0.1;
                }

                if (this.preferences.drinks === 'Yes') {
                    probability *= this.preferences.gender === 'Men' ? 0.56 : 0.48;
                } else if (this.preferences.drinks === 'No') {
                    probability *= this.preferences.gender === 'Men' ? 0.44 : 0.52;
                }

                if (this.preferences.drugs === 'Yes') {
                    probability *= this.preferences.gender === 'Men' ? 0.125 : 0.08;
                } else if (this.preferences.drugs === 'No') {
                    probability *= this.preferences.gender === 'Men' ? 0.875 : 0.92;
                }

                if (this.preferences.excludeMarried === 'Yes') probability *= 0.65;
                if (this.preferences.excludeObese === 'Yes') probability *= 0.78;

                let matchingPeople = Math.round(BOSTON_POPULATION * (probability / 100));
                if (matchingPeople <= 0) matchingPeople = 1;

                const finalProb = Math.max(0, Math.min(probability, 100)).toFixed(2);

                this.result = { percentage: finalProb, people: matchingPeople.toLocaleString() };
                this.matchingPeople = matchingPeople;
            },
            async handleDownloadImage() {
                if (!this.$refs.shareRef || !this.result.percentage) return;
                const canvas = await html2canvas(this.$refs.shareRef as HTMLElement, {
                    backgroundColor: null, // to clip edges of the rounded image
                    scale: 2,
                });
                const dataUrl = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'lola-share.png';
                link.click();
            },
            handleShareX() {
                if (!this.result.percentage) return;
                const singular = parseInt(this.result.people.replace(/,/g, ''), 10) === 1;
                const textBlock = singular
                    ? `Fun fact: @LolaDating says there is only 1 person in Boston who meets my standards!😂\nWant to know yours? Visit lola.com/demo`
                    : `Fun fact: @LolaDating says there are ${this.result.people} people in Boston who meet my standards!😂\nWant to know yours? Visit lola.com/demo`;
                const tweet = encodeURIComponent(textBlock);
                const url = `https://x.com/intent/tweet?text=${tweet}`;
                window.open(url, '_blank', 'noopener,noreferrer');
            },
            handleShareFacebook() {
                if (!this.result.percentage) return;
                const singular = parseInt(this.result.people.replace(/,/g, ''), 10) === 1;
                const textBlock = singular
                    ? `Fun fact: @LolaDating says there is only 1 person in Boston who meets my standards!😂\nWant to know yours? Visit lola.com/demo`
                    : `Fun fact: @LolaDating says there are ${this.result.people} people in Boston who meet my standards!😂\nWant to know yours? Visit lola.com/demo`;
                const fbText = encodeURIComponent(textBlock);
                const fbUrl = `https://www.facebook.com/sharer/sharer.php?quote=${fbText}&u=https://lola.com/demo`;
                window.open(fbUrl, '_blank', 'noopener,noreferrer');
            },
            handleDownloadLola() {
                window.open('https://xbgwl.app.link/vi0TyBtTVFb?_p=c1103fdc981c66f2fc1d', '_blank', 'noopener,noreferrer');
            },
        },
    });
