
    import Vue, { PropOptions, PropType } from 'vue';
    import { Utils } from '@/tsfiles/utils';
    type Option = { value: string; text: string };

    export default Vue.extend({
        name: 'MultiCheckboxDropdown',

        components: {},

        props: {
            label: {
                type: String,
                required: true,
                default: 'Select...',
            } as PropOptions<string>,
            options: {
                type: Array as PropType<Option[]>,
                required: true,
            },
            selectedValues: {
                type: Array,
                required: true,
            } as PropOptions<string[]>,
        },

        data() {
            return {
                open: false,
                optionsInternal: [] as Option[],
                selectedValuesInternal: [] as string[],
                labelInternal: '',
            };
        },
        watch: {
            label: {
                immediate: true,
                handler(newVal: string, oldVal: string) {
                    if (newVal) {
                        this.labelInternal = newVal;
                    } else {
                        this.labelInternal = 'Select';
                    }
                },
            },
            options: {
                immediate: true,
                handler(newVal: string[], oldVal: string[]) {
                    if (newVal && newVal.length > 0) {
                        this.optionsInternal = Utils.deepCopy(newVal);
                    } else {
                        this.optionsInternal = [];
                    }
                },
            },

            selectedValues: {
                immediate: true,
                handler(newVal: string[], oldVal: string[]) {
                    if (newVal && newVal.length > 0) {
                        this.selectedValuesInternal = Utils.deepCopy(newVal);
                    } else {
                        this.selectedValuesInternal = [];
                    }
                },
            },
        },
        computed: {
            summary(): string | undefined {
                const selectedCount = this.selectedValuesInternal.length;
                if (selectedCount === 0) {
                    return 'Select...';
                } else if (selectedCount === 1) {
                    return this.options.find((o) => o.value === this.selectedValuesInternal[0])?.text;
                } else {
                    return (
                        this.selectedValuesInternal
                            .map((v) => this.options.find((o) => o.value === v)?.text)
                            .slice(0, 2)
                            .join(', ') + (selectedCount > 2 ? ` + ${selectedCount - 2}` : '')
                    );
                }
            },
        },
        methods: {
            toggleDropdown() {
                this.open = !this.open;
            },
            toggleItem(value: string) {
                const alreadySelected = this.selectedValuesInternal.includes(value);
                let newValues = {};

                if (value === 'any') {
                    if (alreadySelected) {
                        newValues = [];
                    } else {
                        newValues = ['any'];
                    }
                } else {
                    if (alreadySelected) {
                        newValues = this.selectedValuesInternal.filter((x) => x !== value);
                    } else {
                        newValues = this.selectedValuesInternal.filter((x) => x !== 'any').concat(value);
                    }
                }
                this.$emit('update:selectedValues', newValues);
            },
            handleClickOutside(event: any) {
                if (this.$refs.containerRef && !(this.$refs.containerRef as Element).contains(event.target)) {
                    this.open = false;
                }
            },
        },
        mounted() {
            document.addEventListener('mousedown', this.handleClickOutside);
        },
        beforeDestroy() {
            document.removeEventListener('mousedown', this.handleClickOutside);
        },
    });
