
    import Vue, { PropOptions } from 'vue';
    import RangeSlider from 'vue-range-slider';
    import 'vue-range-slider/dist/vue-range-slider.css';

    export default Vue.extend({
        name: 'SingleSlider',
        components: {
            RangeSlider,
        },
        props: {
            value: {
                type: Number,
                required: true,
            },
            min: {
                type: Number,
                default: 0,
            },
            max: {
                type: Number,
                default: 100,
            },
            step: {
                type: Number,
                default: 1,
            },
            showTooltip: {
                type: Boolean,
                default: true,
            },
            formatThumbValue: {
                type: Function,
                default: null,
            } as PropOptions<Function | null>,
        },
        data() {
            return {
                valueInternal: this.value, // Initial value
                showTooltipInternal: this.showTooltip || true,
                tooltipStyle: {
                    left: '0px',
                    top: '0px',
                },
            };
        },
        watch: {
            value: {
                immediate: true,
                handler(newValues: number, oldValues: number) {
                    this.valueInternal = newValues;
                    this.updateTooltip();
                },
            },
        },
        mounted() {
            this.updateTooltip();
        },

        methods: {
            updateTooltip() {
                this.$nextTick(() => {
                    const knob = this.$el.querySelector('.range-slider-knob');
                    if (knob) {
                        const knobRect = knob.getBoundingClientRect();
                        const containerRect = this.$el.getBoundingClientRect();
                        this.tooltipStyle.left = `${knobRect.left - containerRect.left + knobRect.width / 2}px`;
                        this.tooltipStyle.top = `${knobRect.top - containerRect.top - 30}px`; // Adjust the top position as needed
                    }
                });
            },
            handleInput(value: number) {
                this.valueInternal = value;
                this.$emit('update:value', value);
            },
            formattedValue() {
                return this.formatThumbValue ? this.formatThumbValue(this.value) : this.value;
            },
        },
    });
